.button-sty {
  width: 200px;
  height:200px;
}

.d-1 {
  --c: var(--yellow); /* the color */
  --b: .1em;    /* border length*/
  --d: 10px;    /* the cube depth */

  font-size: var(--card-font);
  --_s: calc(var(--d) + var(--b));

  color: var(--c);
  border: solid #0000;
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background:
          conic-gradient(at left var(--d)  bottom var(--d),
                  #0000 90deg, rgb(1 1 1 /0.5) 0 225deg,rgb(1 1 1 /0.3) 0) border-box,
          conic-gradient(at left var(--_s) bottom var(--_s),
                  #0000 90deg,var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
  transform: translate(calc(0px + var(--d)/-1),var(--d));
  clip-path:
          polygon(
                          var(--d) 0%,
                          var(--d) 0%,
                          100% 0%,
                          100% calc(100% - var(--d) + 0px),
                          100% calc(100% - var(--d) + 0px),
                          var(--d) calc(100% - var(--d) + 0px)
          );
  transition: 0.3s;
  width: 300px;
  height: 300px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  padding: 40px;
  color: var(--white);

  margin: 0 10px;
  span{
    width: 250px;
    align-self: flex-start;
  }
}

.d-1:hover {
  transform: translate(0,0);
  clip-path:
          polygon(
                          0% var(--d),
                          var(--d) 0%,
                          100% 0%,
                          100% calc(100% - var(--d) + 0px),
                          calc(100% - var(--d) + 0px) 100%,
                          0% 100%
          );
}
//.d-2 {
//  --c: #CC333F; /* the color */
//  --b: .1em;    /* border length*/
//  --d: 20px;    /* the cube depth */
//
//  --_s: calc(var(--d) + var(--b));
//  color: var(--c);
//  border: solid #0000;
//  border-width: var(--b) var(--_s) var(--_s) var(--b);
//  background:
//          conic-gradient(from -90deg at right var(--d)  bottom var(--d),
//                  #0000 90deg, rgb(255 255 255 /0.3) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
//          conic-gradient(at right var(--_s) + 0px + bottom var(--_s),
//                  var(--c) 270deg,#0000 0) 100% 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
//  transform: translate(var(--d),var(--d));
//  clip-path:
//          polygon(
//                          0% 0%,
//                          calc(100% - var(--d) + 0px) 0%,
//                          calc(100% - var(--d) + 0px) 0%,
//                          calc(100% - var(--d) + 0px) calc(100% - var(--d) + 0px),
//                          0 calc(100% - var(--d) + 0px),
//                          0 calc(100% - var(--d) + 0px)
//          );
//  transition: 0.5s;
//}

//body {
//  //height: 100vh;
//  margin: 0;
//  display: grid;
//  grid-template-columns: auto auto;
//  gap: 20px;
//  place-content: center;
//  align-items: center;
//}

@media screen and (min-width: 1px) and (max-width: 599px) {
  .d-1 {
    //align-self: center;
    width: 150px;
    height: 150px;
    font-size: 22px;
    margin: 10px 0;
    //padding-left: 20px;
    span {
      width: 165px;
      //padding: 0;
    }
  }
  .d-1:hover {

  }


}

@media screen and (min-width: 600px) and (max-width: 821px){
  .d-1 {
    //align-self: center;
    width: 200px;
    height: 200px;
    font-size: 30px;
    margin: 10px 0;

    span {
      width: 225px;
    }
  }
}

@media screen and (min-width: 821px) and (max-width: 1027px){
  .d-1 {
    //align-self: center;
    width: 150px;
    height: 150px;
    font-size: 22px;
    span {
      width: 165px;
    }
  }
}
 @media screen and (min-width: 1028px) {
   .d-1 {
     //align-self: center;
     width: 200px;
     height: 200px;
     font-size: 30px;
     margin: 0 20px;
     span {
       width: 225px;
     }
   }
 }
//
//@media screen and (min-width: 768px){
//  .d-1 {
//    //align-self: center;
//    width: 250px;
//    height: 250px;
//    font-size: 1em;
//  }
//  .d-1:hover {
//
//  }
//}