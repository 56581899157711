#text-box {
  width: 85%;
  color: var(--white);
  background: var(--black);
  outline: none;
}

//.form {
//  width: fit-content;
//  height: fit-content;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//  //border: 2px solid var(--yellow);
//  padding: 20px;
//}
///* Form wrapper styling */
.inputbox {
  margin-top: 2vh;
  width: 250px;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid var(--yellow);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  background: var(--black);
  outline: none
}

//
///* Form text input */
//
.inputbox input {
  border: 0;
  border-radius: 3px 0 0 3px;
}

/* Form submit button */
.inputbox button {
  overflow: visible;
  position: relative;
  outline: none;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.submit-button {
  width: 100px;
  height: 40px;
  background: var(--yellow);
  border: 0;
  border-radius: 6px;
  font-weight: var(--button-weight);
  margin-top: 20px;
}
.submit-button:hover {
  background: #ecd370;
}

.intake-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}

@media screen and (min-width: 1px) and (max-width: 599px) {
  .form {
    justify-content: center;
  }

  .intake-form {
    align-items: center;
  }
}

@media screen and (min-width: 600px) and (max-width: 821px){
  .d-1 {
    //align-self: center;
    width: 200px;
    height: 200px;
    font-size: 30px;
    margin: 10px 0;

    span {
      width: 225px;
    }
  }

  .intake-form {
    justify-content: center;
    align-content: center;
  }
}

@media screen and (min-width: 821px) and (max-width: 1027px){
  .inputbox {
    width: 50px;
    height: 50px;
  }

  //.intake-form {
  //  justify-content: center;
  //  align-content: center;
  //}
}
@media screen and (min-width: 1028px) {
  .inputbox {
    width: 350px;
    height: 30px;
    font-size: 22px;
  }


  .submit-button {
    width: 200px;
    height: 50px;
    font-size: 22px;
    font-weight: var(--button-weight);
  }
}