// // HEADING // //
.heading {
  height: auto;
  width: 50%;
  color: var(--white);
  font-size: var(--header-font);
  letter-spacing: 1vw;
  line-height: var(--heading-spacing);


  &.text {
    display: flex;
    flex-direction: column;
  }
}



.heading-sec {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5%;
  height: var(--section-size);


  //position: fixed; /* make sure it stays put so we can see it! */
  //
  //animation: rotate 1s linear infinite;
  //animation-play-state: paused;
  //animation-delay: calc(var(--scroll) * -1s);
}


@media screen and (max-width: 414px){
  .heading-sec {
    flex-direction: column;
    height: 90vh;
  }

  .heading {
    width: 90%;
    margin-top: 10%;
    flex-direction: row;

    display: flex;
    justify-content: center;
    align-items: center;

    &.text {
      width: 50%;
      height: 50vh;
      text-align: center;
      font-size: 5vw;
    }
  }
}