@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600;700&display=swap');

:root {
    /*  COLORS  */
    --yellow: #face1f;
    --black: #000000;
    --white: #ffffff;

    /*  FAMILY */
    --family: Montserrat;

    /*  WEIGHT  */
    --header-weight: 700;
    --subheader-weight: 400;
    --body-weight: 300;
    --button-weight: 700;

    /*  FONT SIZES  */
    --header-font: 2vw;
    --subheader-font: 3vw;
    --body-font: 1.75vw;
    --card-font: 2vw;

    /*  SPACING  */
    --title-spacing: 1vw;
    --heading-spacing: 2em;

    /*  LINE HEIGHT  */
    --subheader-height: 1em;

    /*  MARGIN  */
    --subheader-margin: 1rem;

    /*  SECTION  */
    --section-size: 75vh;

}

/*@font-face {*/
/*    font-family: "Regular Montserrat";*/
/*    src: local("Montserrat Regular"), local("Montserrat-Regular"),*/
/*    url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm45xW4.ttf)*/
/*    format("truetype");*/
/*}*/

/*@font-face {*/
/*    font-family: "Semibold Montserrat";*/
/*    src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),*/
/*    url("public/Montserrat-SemiBold.ttf")*/
/*    format("truetype");*/
/*}*/

html * {
    font-family: 'Montserrat', sans-serif;
}

.header {
    color: var(--yellow);
    font-family: var(--family);
    font-size: var(--header-font);
    font-weight: var(--header-weight);
    /*letter-spacing: var(--heading-spacing);*/
}

.subheader {
    color: var(--white);
    font-family: var(--family);
    font-size: var(--subheader-font);
    font-weight: var(--subheader-weight);

    line-height: var(--subheader-height);
    margin-top: var(--subheader-margin);
    overflow-y: hidden;
}

.body {
    color: var(--white);
    font-family: var(--family);
    font-size: var(--body-font);
    font-weight: var(--body-weight);
    margin-top: var(--subheader-margin);
}

/*@media screen and (max-width: 1000px) {*/
/*    :root {*/
/*        !*--white: #000000;*!*/
/*    }*/
/*}*/

/* MOBILE SIZING*/
@media screen and (max-width: 414px) {
    :root {
        --header-font: 4.5vw;
        --subheader-font: 5vw;
        --body-font: 3.25vw;
        --section-size: 70vh;
        --subheader-margin: 0.3rem;
    }
}