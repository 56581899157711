body {
  background-color: var(--black);
  font-family: 'Montserrat', Arial, serif;
  font-size: 3.5vw;
}


@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

// // SCROLLING TEXT // //
.scrolling-words {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 5%;
  height: calc(var(--section-size) - 30vh);
  flex: 1;

  &.container {
    color: var(--white);
    letter-spacing: 0.25vw;
    line-height: 4vw;
    display: flex;
    align-items: center;
    font-size: var(--subheader-font);
    font-weight: var(--subheader-font);

    height: 1em; //todo
    border-left: solid var(--yellow) 0.1em;
    border-right: solid var(--yellow) 0.1em;
  }


}

.scrolling-words-box {
  height: 3rem;
  margin: auto;
  overflow: hidden;
  ul {
    margin: 0 0.625rem;
    padding: 0;
    animation: scrollUp 4s infinite;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 3rem;
      list-style: none;
      color: var(--yellow);
    }
  }
}



// Variables
$item-count: 3;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 15%}, #{$i * 25%} {
      transform: translateY(calc((-100% / #{$item-count}) * #{$i}) );
    }
  }
}

.sec {
  padding: 5%;
  display: flex;
  //flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  height: var(--section-size);
  width: 90%;

  &.callout {
    height: 20vh;
  }

  &.callout-div {
    width: 70%;
    height: 1.75em;
    justify-self: center;
    align-self: center;
    &.text {
      color: white;
      font-size: var(--subheader-font);
      border-left: solid var(--yellow) 0.1em;
      border-right: solid var(--yellow) 0.1em;
    }
  }
}
// // INFO // //
.info-sec {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5%;
  height: var(--section-size);
  width: 95%;
  flex: 1;
  align-items: center;

  &.content {
    width: 70%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}

.card-sec {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--section-size);
}

.drone-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
  height: var(--section-size);
  width: 90%;
}

.benefits-sec {
  padding: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--section-size);
  flex-direction: row;
  width: 50%;
  line-height: 4rem;
}

.benefits-left {
  //width: 90vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.benefits-right{
  width: 50vw;
  background: var(--yellow);

}

// MOBILE SIZING
@media screen and (max-width: 1024px) {
  :root {
  //  --header-font: 2.75vw;
  //  --section-size: 70vh;
  //  --subheader-margin: 0.5rem;
  }
}

// MOBILE SIZING
@media screen and (max-width: 414px) {
  //:root {
  //  --header-font: 2.75vw;
  //  --section-size: 50vh;
  //  --subheader-margin: 0.5rem;
  //}

  .info-sec {

    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    //height:  30vh;

    &.content {
      width: 60% !important;
      align-items: center;
    }
  }

  .scrolling-words {
    flex-direction: column;
  }

  .scrolling-words-box {
    height: 1rem;
    margin: 1rem;
    overflow: hidden;
    font-size: 20px;
    ul {
      margin: 0 0.625rem;
      padding: 0;
      animation: scrollUp 4s infinite;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        list-style: none;
        color: var(--yellow);
      }
    }
  }
  //
  //$item-count: 3;
  //
  //@keyframes scrollUp {
  //  @for $i from 1 through ($item-count - 1) {
  //    #{($i * 30) - 15%}, #{$i * 25%} {
  //      transform: translateY(calc((-100% / #{$item-count}) * #{$i}) );
  //    }
  //  }
  //}


}

@media screen and (max-width: 820px) {
  .card-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }


}