.footer-sec {
  width: 96%;
  height: 15vh;

  padding: 2%;
  border-top: solid var(--white);
  border-width: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.linkedin-button {
    border: 0;
    width: 250px;
    padding: 0;
    background: transparent;
    display: flex;
    text-align: right;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: fit-content;
  }


}

.linkedin-img {
  width: 75px;
  height: auto;
  margin-left: 10px;
}



@media screen and (min-width: 1px) and (max-width: 414px) {
  .footer-sec {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 2%;
    width: 96%;
    margin-bottom: 1vh;
    //

    &.linkedin-button {
      width: 150px;
      margin: 0;
    }
    &.linkedin {
      border: 0;
      margin-bottom: 5%;
      //width: 20%;
      //flex-direction: column;
      //justify-content: center;
    }
  }

  .linkedin-img {
    width: 50px;
    height: auto;
    margin-left: 0;
  }
}

@media screen and (min-width: 415px) and (max-width: 600px) {
  .footer-sec {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 2%;
    width: 96%;
    margin-bottom: 1vh;
    //

    &.linkedin-button {
      //width: 150px;
      margin: 0;
    }
    &.linkedin {
      border: 0;
      margin-bottom: 5%;
      //width: 20%;
      //flex-direction: column;
      //justify-content: center;
    }
  }

  .linkedin-img {
    width: 50px;
    height: auto;
    margin-left: 0;
  }
}



@media screen and (min-width: 601px) and (max-width: 824px) {
  .footer-sec {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 2%;
    width: 96%;
    margin-bottom: 1vh;
    //

    &.linkedin-button {
      width: 150px;
      margin: 0;
    }
    &.linkedin {
      border: 0;
      margin-bottom: 5%;
      //width: 20%;
      //flex-direction: column;
      //justify-content: center;
    }
  }

  .linkedin-img {
    width: 50px;
    height: auto;
    margin-left: 0;
  }
}


//@media screen and (max-width: 600px) {
//  .footer-sec {
//    flex-direction: column;
//    justify-content: center;
//    //height:20vh;
//    padding: 0;
//    padding-top: 5%;
//    width: 100%;
//    //
//    &.linkedin {
//      margin-bottom: 5%;
//      //width: 20%;
//      //flex-direction: column;
//      //justify-content: center;
//    }
//  }
//}